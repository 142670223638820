<template>
  <GModal name="modal" class="modal modal-add-product">
    <div slot="header">
      <img src="/static/img/icons/add-product-success.svg" />
      <div class="modal-title">
        <div class="text-red">
          Produk berhasil <br />
          ditambahkan!
        </div>
      </div>
    </div>
    <div slot="body">
      <div class="continue-shopping" @click="$emit('continue-shopping')">
        <span>Lanjut belanja</span>
      </div>
      <div class="show-cart" @click="$emit('show-cart')">
        <span>Lihat keranjang</span>
      </div>
    </div>
  </GModal>
</template>

<script>
export default {
  name: 'ModalAddProduct',
};
</script>
